<template>
  <div class="quotes-page">
    <h1>Lista Preventivi</h1>

    <!-- Barra di ricerca e filtro -->
    <div class="search-bar">
      <input
        type="text"
        placeholder="Cerca..."
        v-model="searchQuery"
        @input="filterQuotes"
      />
      <select v-model="statusFilter" @change="filterQuotes" class="filter-select">
        <option value="">Tutti gli stati</option>
        <option value="approvato">Approvato</option>
        <option value="attesa">Attesa</option>
        <option value="rifiutato">Rifiutato</option>
        <option value="terminato">Terminato</option>
      </select>
      <button @click="createNewQuote" class="btn-create">+ Nuovo Preventivo</button>
    </div>

    <!-- Indicatore di contatore -->
    <p class="counter">Visualizzati {{ filteredQuotes.length }} preventivi su {{ quotes.length }}</p>

    <!-- Tabella dei preventivi -->
    <table class="quotes-table">
      <thead>
        <tr>
          <th @click="sortBy('name')" class="sortable">
            Cliente
            <span class="sort-icon">
              <svg v-if="sortKey === 'name' && sortOrders.name === 1" width="20" height="20" viewBox="0 0 24 24">
                <path fill="currentColor" d="M7 14L12 9L17 14H7Z" />
              </svg>
              <svg v-else width="20" height="20" viewBox="0 0 24 24">
                <path fill="currentColor" d="M7 10L12 15L17 10H7Z" />
              </svg>
            </span>
          </th>
          <th @click="sortBy('indirizzo')" class="sortable">
            Indirizzo
            <span class="sort-icon">
              <svg v-if="sortKey === 'indirizzo' && sortOrders.indirizzo === 1" width="20" height="20" viewBox="0 0 24 24">
                <path fill="currentColor" d="M7 14L12 9L17 14H7Z" />
              </svg>
              <svg v-else width="20" height="20" viewBox="0 0 24 24">
                <path fill="currentColor" d="M7 10L12 15L17 10H7Z" />
              </svg>
            </span>
          </th>
          <th @click="sortBy('date')" class="sortable">
            Data Sopralluogo
            <span class="sort-icon">
              <svg v-if="sortKey === 'date' && sortOrders.date === 1" width="20" height="20" viewBox="0 0 24 24">
                <path fill="currentColor" d="M7 14L12 9L17 14H7Z" />
              </svg>
              <svg v-else width="20" height="20" viewBox="0 0 24 24">
                <path fill="currentColor" d="M7 10L12 15L17 10H7Z" />
              </svg>
            </span>
          </th>
          <th @click="sortBy('amount')" class="sortable">
            Importo
            <span class="sort-icon">
              <svg v-if="sortKey === 'amount' && sortOrders.amount === 1" width="20" height="20" viewBox="0 0 24 24">
                <path fill="currentColor" d="M7 14L12 9L17 14H7Z" />
              </svg>
              <svg v-else width="20" height="20" viewBox="0 0 24 24">
                <path fill="currentColor" d="M7 10L12 15L17 10H7Z" />
              </svg>
            </span>
          </th>
          <th @click="sortBy('status')" class="sortable">
            Stato
            <span class="sort-icon">
              <svg v-if="sortKey === 'status' && sortOrders.status === 1" width="20" height="20" viewBox="0 0 24 24">
                <path fill="currentColor" d="M7 14L12 9L17 14H7Z" />
              </svg>
              <svg v-else width="20" height="20" viewBox="0 0 24 24">
                <path fill="currentColor" d="M7 10L12 15L17 10H7Z" />
              </svg>
            </span>
          </th>
          <th>Azioni</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="quote in this.filteredQuotes" :key="quote._id">
          <td>{{ quote.client.name }}</td>
          <td><a :href="'https://www.google.com/maps/place/' + quote.move.startAddresses[0].address" target="_blank">{{ formatText(quote.move.startAddresses[0].address)}}</a></td>
          <td>{{ formatDate(quote.move.surveyDatetime) }}</td>
          <td>€ {{ quote.billing.totalPrice }}</td>
          <td>
            <span :class="['badge', quote.status]">
              {{ statusLabels[quote.status] }}
            </span>
          </td>
          <td>
            <!--<button @click="openClient(quote._id)" class="btn-action btn-view" title="Apri">-->
              <!-- Icona occhio SVG -->
              <!--<svg width="16" height="16" viewBox="0 0 24 24" fill="white" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 5C7.58 5 4 8.03 2 12C4 15.97 7.58 19 12 19C16.42 19 20 15.97 22 12C20 8.03 16.42 5 12 5ZM12 17C9.24 17 7 14.76 7 12C7 9.24 9.24 7 12 7C14.76 7 17 9.24 17 12C17 14.76 14.76 17 12 17ZM12 9C10.34 9 9 10.34 9 12C9 13.66 10.34 15 12 15C13.66 15 15 13.66 15 12C15 10.34 13.66 9 12 9Z" />
              </svg>
            </button>-->
            <button @click="editQuote(quote._id)" class="btn-action btn-edit" title="Modifica">
              <!-- Icona matita SVG -->
              <svg width="16" height="16" viewBox="0 0 24 24" fill="white" xmlns="http://www.w3.org/2000/svg">
                <path d="M3 17.25V21H6.75L17.81 9.94L14.06 6.19L3 17.25Z" />
              </svg>
            </button>
            <button @click="deleteQuote(quote._id, quote.client.name)" class="btn-action btn-delete" title="Elimina">
              <svg width="16" height="16" viewBox="0 0 24 24" fill="white" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 3V4H4V6H20V4H15V3H9Z" />
                <path d="M10 9V17H8V9H10ZM14 9V17H12V9H14ZM16 9V17H18V9H16ZM6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7H6V19Z" />
              </svg>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <br>
    <div v-if="this.quotes.length == 0" style="width:200px; margin:0 auto;">Nessun preventivo trovato</div>
    <div v-if="this.quotes.length != 0 && this.filteredQuotes.length == 0" style="width:250px; margin:0 auto;">Nessun preventivo selezionato</div>

    <!-- Paginazione -->
    <div class="pagination">
      <button @click="prevPage" :disabled="page === 1">Precedente</button>
      <span>Pagina {{ page }} di {{ totalPages ? totalPages : 1 }}</span>
      <button @click="nextPage" :disabled="page === totalPages">Successivo</button>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "QuotesView",
  data() {
    return {
      searchQuery: "",
      statusFilter: "",
      quotes: [], // Popolato tramite API
      filteredQuotes: [],
      page: 1,
      limit: 10, // Numero di clienti per pagina
      totalPages: 1,
      sortKey: "",
      sortOrders: {
        name: -1,
        indirizzo: -1,
        date: -1,
        amount: -1,
        status: -1,
      },
      statusLabels: {
        approvato: "Approvato", 
        attesa: "Attesa",
        rifiutato: "Rifiutato",
        terminato: "Terminato",
      },
    };
  },
  mounted() {
    this.fetchQuotes();
  },
  methods: {
    async fetchQuotes(){
      try {
        const response = await axios.get('https://sincrocoop.it/api/quotes/list');
        this.quotes = response.data;
        this.filteredQuotes = this.quotes;
      } catch (error) {
        console.error("Errore durante il caricamento dei preventivi:", error);
        //alert("Errore durante il caricamento dei preventivi." + (error.response?.data || error || 'Errore sconosciuto'));
      }
    },
    sortBy(key) {
      this.sortKey = key;
      this.sortOrders[key] = this.sortOrders[key] * -1;

      this.filteredQuotes.sort((a, b) => {
        let valueA, valueB;

        switch (key) {
          case 'name':
            valueA = a.client.name.toLowerCase();
            valueB = b.client.name.toLowerCase();
            break;
          case 'indirizzo':
            valueA = a.move.startAddresses[0].address.toLowerCase();
            valueB = b.move.startAddresses[0].address.toLowerCase();
            break;
          case 'date':
            valueA = new Date(a.createdAt);
            valueB = new Date(b.createdAt);
            break;
          case 'amount':
            valueA = parseFloat(a.billing.totalPrice) || 0;
            valueB = parseFloat(b.billing.totalPrice) || 0;
            break;
          case 'status':
            valueA = a.status || '';
            valueB = b.status || '';
            break;
          default:
            valueA = a[key];
            valueB = b[key];
        }

        if (valueA < valueB) return -1 * this.sortOrders[key];
        if (valueA > valueB) return 1 * this.sortOrders[key];
        return 0;
      });
    },
    filterQuotes() {
      const query = this.searchQuery.toLowerCase();
      this.filteredQuotes = this.quotes.filter(
        (quote) => (
          quote.client.name.toLowerCase().includes(query) || quote.move.startAddresses[0].address.toLowerCase().includes(query) ||  quote.move.surveyDatetime.includes(query) || quote.billing.totalPrice.toString().includes(query)) && (!this.statusFilter || quote.status === this.statusFilter)
      );
    },
    createNewQuote() {
      this.$router.push("/quotesd");
    },
    openClient(quoteId) {
      //this.$router.push(`/quotes/${quoteId}`);
      alert(`Errore preventivo: ${quoteId} troppo grande`);
    },
    editQuote(quoteId) {
      this.$router.push(`/quotesd/${quoteId}`);
    },
    deleteQuote(quoteId, quoteName) {
      if (confirm(`Sei sicuro di voler eliminare il preventivo di: ${quoteName}`)) {
        axios.delete(`https://sincrocoop.it/api/quotes/delete/${quoteId}`)
          .then(() => {
            this.fetchQuotes();
            location.reload();
          })
          .catch(error => {
            console.error('Errore durante l\'eliminazione del Preventivo:', error);
          });
      }
    },
    formatText(str){
      let skippedString = str.trimEnd();
         if(skippedString.length > 40){
             return skippedString.substring(0, 43).trim() + '...';
         }else{
             return str;
         }
    },
    formatDate(date) {
      const d = new Date(date);
      const giorno = String(d.getDate()).padStart(2, '0');
      const mese = String(d.getMonth() + 1).padStart(2, '0');
      const anno = d.getFullYear();
      return `${giorno}/${mese}/${anno}`;
    },
    prevPage() {
      if (this.page > 1) {
        this.page--;
        this.fetchQuotes();
      }
    },
    nextPage() {
      if (this.page < this.totalPages) {
        this.page++;
        this.fetchQuotes();
      }
    }
  },
};
</script>

  
  <style scoped>
  .quotes-page {
    padding: 20px;
    font-family: 'Roboto', sans-serif;
    color: #333;
  }
  
  h1 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #991520;
  }
  
  /* Barra di ricerca e filtro */
  .search-bar {
    margin-bottom: 20px;
    position: relative;
    display: flex;
    gap: 10px;
  }
  
  .search-bar input {
    padding: 8px;
    width: 150px;
    border: 1px solid #ddd;
    border-radius: 25px;
    outline: none;
    font-size: 14px;
    transition: width 0.3s ease, border-color 0.3s;
  }
  
  .search-bar input:focus {
    width: 200px;
    border-color: #991520;
  }
  
  .filter-select {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    outline: none;
    font-size: 14px;
    cursor: pointer;
  }
  
  .btn-create {
    background-color: #28a745;
    color: white;
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
  }
  
  .btn-create:hover {
    background-color: #218838;
  }
  
  /* Indicatore del contatore */
  .counter {
    font-size: 14px;
    color: #666;
    margin-bottom: 10px;
  }
  
  /* Tabella dei preventivi */
  .quotes-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    font-size: 14px;
  }
  
  .quotes-table th,
  .quotes-table td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
  }
  
  .quotes-table th.sortable {
    background-color: #991520;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
    position: relative;
  }
  
  .quotes-table th.sortable:hover {
    background-color: #AE1100;
  }
  
  /* Stile per l'icona di ordinamento */
  .sort-icon {
    margin-left: 5px;
    display: inline-block;
    vertical-align: middle;
    font-size: 12px;
  }
  
  /* Badge di stato */
  .badge {
    padding: 5px 10px;
    border-radius: 4px;
    color: white;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    display: inline-block;
  }
  
  .badge.approvato {
    background-color: #007bff; /* Verde per "Approvato" */
  }
  
  .badge.attesa {
    background-color: #ffc107; /* Arancione per "Attesa" */
  }
  
  .badge.rifiutato {
    background-color: #dc3545; /* Rosso per "Rifiutato" */
  }

  .badge.terminato {
    background-color: #28a745; /* Rosso per "Rifiutato" */
  }
  
  /* Bottoni di azione */
  .btn-action {
    padding: 5px;
    margin: 0 2px;
    border: none;
    cursor: pointer;
    width: 32px;
    height: 32px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
  }
  
  .btn-view {
    background-color: #28a745; /* Verde */
    color: white;
  }

  .btn-edit {
    background-color: #007bff; /* Blu */
    color: white;
  }
  
  .btn-delete {
    background-color: #dc3545; /* Rosso */
    color: white;
  }
  
  .btn-action svg {
    width: 16px;
    height: 16px;
    transition: transform 0.2s;
  }
  
  .btn-action:hover svg {
    transform: scale(1.1);
  }
  
  /* Hover row */
  .quotes-table tbody tr:hover {
    background-color: #f9f9f9;
  }

  /*PAGINAZIONE*/
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination button {
  background-color: #991520;
  color: white;
  border: none;
  padding: 8px 16px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 4px;
}

.pagination button:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}

.pagination span {
  font-size: 16px;
}
  </style>
  