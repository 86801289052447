import { createApp } from 'vue'
import App from './App.vue'
import { createPinia } from "pinia";
import router from './router'
import './assets/style/main.css'

const app = createApp(App);


// Configura Pinia
const pinia = createPinia();
app.use(pinia);
app.use(router);

app.mount('#app');
