
<template>
  <div class="appointments-page">
    <!-- Intestazione Fissa con Riepilogo del Giorno -->
    <div class="header">
      <h1>Appuntamenti di Oggi</h1>
      <p>{{ formattedDate }}</p>
    </div>

    <!-- Sezione di Filtraggio -->
    <div class="filter-bar">
      <a href="/appointments">Tutti gli Appuntamenti</a>
    </div>

    <!-- Lista degli Appuntamenti -->
    <div class="appointments-list">
      <div
        v-for="(appointment, index) in filteredAppointments"
        :key="index"
        class="appointment-card"
      >
        <div class="appointment-info">
          <h2>{{ appointment.clientName }}</h2>
          <p>Indirizzo: {{ appointment.address }}</p>
          <p>Orario: {{ appointment.time }}</p>
          <p>Note: {{ appointment.note }}</p>
        </div>
        <div class="appointment-actions">
          <button @click="viewAppointmentDetails(appointment.id)" class="btn-view">
            <!-- Icona occhio SVG -->
            <svg
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="white"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 5C7.58 5 4 8.03 2 12C4 15.97 7.58 19 12 19C16.42 19 20 15.97 22 12C20 8.03 16.42 5 12 5ZM12 17C9.24 17 7 14.76 7 12C7 9.24 9.24 7 12 7C14.76 7 17 9.24 17 12C17 14.76 14.76 17 12 17ZM12 9C10.34 9 9 10.34 9 12C9 13.66 10.34 15 12 15C13.66 15 15 13.66 15 12C15 10.34 13.66 9 12 9Z"
              />
            </svg>
          </button>
          <button @click="markAppointmentComplete(appointment.id)" class="btn-complete">
            <!-- Icona check SVG -->
            <svg
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="white"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M9 16.17L4.83 12L3.41 13.41L9 19L21 7L19.59 5.59L9 16.17Z" />
            </svg>
          </button>
        </div>
      </div>
    </div>

    <!-- Sezione Grafici -->
    <div class="charts-section">
      <div class="chart-container">
        <h2>Distribuzione degli Status dei Preventivi</h2>
        <canvas id="statusPieChart"></canvas>
      </div>
      <div class="chart-container">
        <h2>Numero di Preventivi per Cliente</h2>
        <canvas id="quotesBarChart"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Chart from 'chart.js/auto';

export default {
  data() {
    return {
      searchQuery: '',
      filterType: 'today', // Filtro predefinito per mostrare gli appuntamenti di oggi
      appointments: [
        {
          id: 1,
          clientName: 'Mario Rossi',
          address: 'Via Roma 123, Milano',
          time: '09:30',
          note: 'Primo sopralluogo per preventivo',
          date: new Date().toISOString().split('T')[0], // Data di oggi
        },
        {
          id: 2,
          clientName: 'Anna Bianchi',
          address: 'Corso Italia 45, Torino',
          time: '11:00',
          note: 'Sopralluogo tecnico per Scarico Merci',
          date: new Date().toISOString().split('T')[0], // Data di oggi
        },
        {
          id: 3,
          clientName: 'Mario Rossi',
          address: 'Via Roma 123, Milano',
          time: '09:30',
          note: 'Primo sopralluogo per preventivo',
          date: new Date().toISOString().split('T')[0], // Data di oggi
        },
        {
          id: 4,
          clientName: 'Anna Bianchi',
          address: 'Corso Italia 45, Torino',
          time: '11:00',
          note: 'Sopralluogo tecnico per Scarico Merci',
          date: new Date().toISOString().split('T')[0], // Data di oggi
        },
        // Altri appuntamenti...
      ],
      filteredAppointments: [],
      quotes: [],
      statusCounts: {
        approvato: 0,
        attesa: 0,
        rifiutato: 0,
        terminato: 0,
      },
      quotesPerClient: {},
    };
  },
  computed: {
    formattedDate() {
      const options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      };
      return new Date().toLocaleDateString('it-IT', options);
    },
  },
  mounted() {
    this.filterAppointments();
    this.fetchQuotes();
  },
  methods: {
    filterAppointments(type = 'today') {
      this.filterType = type;
      const today = new Date().toISOString().split('T')[0];

      if (type === 'today') {
        this.filteredAppointments = this.appointments.filter(
          (appointment) => appointment.date === today
        );
      }
    },
    viewAppointmentDetails(appointmentId) {
      this.$router.push(`/appointments/${appointmentId}`);
    },
    markAppointmentComplete(appointmentId) {
      alert(`Appuntamento con ID ${appointmentId} completato!`);
      // Logica per marcare l'appuntamento come completato...
    },
    fetchQuotes() {
      axios
        .get('https://sincrocoop.it/api/quotes/list')
        .then((response) => {
          this.quotes = response.data;
          this.processQuotesData();
          this.renderCharts();
        })
        .catch((error) => {
          console.error('Errore durante il recupero dei preventivi:', error);
        });
    },
    processQuotesData() {
      // Resetta i contatori
      this.statusCounts = {
        approvato: 0,
        attesa: 0,
        rifiutato: 0,
        terminato: 0,
      };
      this.quotesPerClient = {};

      this.quotes.forEach((quote) => {
        // Conta gli status
        if (this.statusCounts[quote.status] !== undefined) {
          this.statusCounts[quote.status]++;
        }

        // Conta i preventivi per cliente
        const clientName = quote.client.name || 'Sconosciuto';
        if (this.quotesPerClient[clientName]) {
          this.quotesPerClient[clientName]++;
        } else {
          this.quotesPerClient[clientName] = 1;
        }
      });
    },
    renderCharts() {
      // Grafico a torta per gli status
      const ctxPie = document.getElementById('statusPieChart').getContext('2d');
      new Chart(ctxPie, {
        type: 'pie',
        data: {
          labels: Object.keys(this.statusCounts),
          datasets: [
            {
              data: Object.values(this.statusCounts),
              backgroundColor: ['#007bff', '#ffc107', '#dc3545', '#28a745'],
            },
          ],
        },
        options: {
          responsive: true,
        },
      });

      // Grafico a barre per i preventivi per cliente
      const ctxBar = document.getElementById('quotesBarChart').getContext('2d');
      new Chart(ctxBar, {
        type: 'bar',
        data: {
          labels: Object.keys(this.quotesPerClient),
          datasets: [
            {
              label: 'Numero di Preventivi',
              data: Object.values(this.quotesPerClient),
              backgroundColor: '#AE1100',
            },
          ],
        },
        options: {
          responsive: true,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.home {
  text-align: center;
  padding-bottom: 100px;
}

.dashboard-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 20px;
}

.blog-card {
  display: flex;
  flex-direction: column;
  width: 280px;
  margin: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;
  transition: transform 0.3s ease;
}

.blog-card:hover {
  transform: scale(1.05);
}

.blog-card .meta {
  position: relative;
}

.blog-card .photo {
  background-size: cover;
  background-position: center;
}

.blog-card .photo img {
  width: 280px;
  height: 140px;
}

.blog-card .description {
  padding: 20px;
  background-color: white;
}

.blog-card .description h1 {
  margin-top: 0;
  font-size: 20px;
  width: 240px;
  height: 24px;
}

.blog-card .description h2 {
  font-size: 16px;
  color: #999;
  margin-top: 5px;
  width: 240px;
  height: 40px;
}

.blog-card .description p {
  font-size: 14px;
  color: #333;
}

.blog-card .read-more a {
  display: inline-block;
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #991520;
  font-weight: 500;
  color: white;
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.3s;
  width: 140px;
  height: 17px;
}

.blog-card .read-more a:hover {
  background-color: #AE1100;
}

.blog-card.alt {
  background-color: #f9f9f9;
}

.appointments-page {
  padding: 20px 10px 20px 10px;
  max-width: 900px;
  margin: auto;
  font-family: 'Roboto', sans-serif;
  background-color: #f9f9f9;
}

.header {
  text-align: center;
  margin-bottom: 20px;
}

.header h1 {
  color: #991520;
  font-size: 24px;
  margin-bottom: 5px;
}

.header p {
  color: #666;
  font-size: 16px;
}

.filter-bar {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  justify-content: space-around;
}

.filter-bar a {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background-color: #991520;
  text-decoration: none;
  color: white;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

.filter-bar a.active {
  background-color: #AE1100;
}

.appointments-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-height: 200px;
  overflow-y: scroll;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #aaa; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #AE1100; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #991520; 
}

.appointment-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  margin-right: 10px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
}

.appointment-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.appointment-info {
  max-width: 75%;
}

.appointment-info h2 {
  color: #333;
  margin: 0 0 5px;
  font-size: 18px;
}

.appointment-info p {
  color: #555;
  font-size: 14px;
  margin: 2px 0;
}

.appointment-actions {
  display: flex;
  gap: 10px;
}

.btn-view,
.btn-complete {
  border: none;
  cursor: pointer;
  padding: 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  transition: background-color 0.3s;
}

.btn-view {
  background-color: #007bff;
}

.btn-complete {
  background-color: #28a745;
}

.btn-view:hover {
  background-color: #0056b3;
}

.btn-complete:hover {
  background-color: #218838;
}

.charts-section {
  max-width: 1200px;
  margin: 40px auto;
  text-align: center;
}

.charts-section h2 {
  margin-bottom: 20px;
  color: #333;
}

#statusPieChart,
#quotesBarChart {
  max-width: 100%;
  height: 400px;
}

.appointments-page {
  padding: 20px 10px 20px 10px;
  max-width: 1200px;
  margin: auto;
  font-family: 'Roboto', sans-serif;
  background-color: #f9f9f9;
}

.header {
  text-align: center;
  margin-bottom: 20px;
}

.header h1 {
  color: #991520;
  font-size: 24px;
  margin-bottom: 5px;
}

.header p {
  color: #666;
  font-size: 16px;
}

.filter-bar {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  justify-content: space-around;
}

.filter-bar a {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background-color: #991520;
  text-decoration: none;
  color: white;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

.filter-bar a.active {
  background-color: #AE1100;
}

.appointments-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-height: 200px;
  overflow-y: scroll;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #aaa;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #AE1100;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #991520;
}

.appointment-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  margin-right: 10px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
}

.appointment-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.appointment-info {
  max-width: 75%;
}

.appointment-info h2 {
  color: #333;
  margin: 0 0 5px;
  font-size: 18px;
}

.appointment-info p {
  color: #555;
  font-size: 14px;
  margin: 2px 0;
}

.appointment-actions {
  display: flex;
  gap: 10px;
}

.btn-view,
.btn-complete {
  border: none;
  cursor: pointer;
  padding: 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  transition: background-color 0.3s;
}

.btn-view {
  background-color: #007bff;
}

.btn-complete {
  background-color: #28a745;
}

.btn-view:hover {
  background-color: #0056b3;
}

.btn-complete:hover {
  background-color: #218838;
}

.charts-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 40px auto;
  gap: 20px;
}

.chart-container {
  width: 45%;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.chart-container h2 {
  margin-bottom: 20px;
  color: #333;
  font-size: 18px;
}

.chart-container canvas {
  max-width: 100%;
  height: 250px;
}
</style>
