import { defineStore } from "pinia";

export const useUserStore = defineStore("user", {
  state: () => ({
    _id: null,
    user: null,
    name: null,
    level: null,
    email: null,
    cell: null,
    token: null, // Salviamo il token qui
  }),
  actions: {
    setUserData(data, token) {
      this._id = data._id;
      this.user = data.user;
      this.name = data.name;
      this.level = data.level;
      this.email = data.email;
      this.cell = data.cell;
      this.token = token; // Salviamo il token
    },
    clearUserData() {
      this._id = null;
      this.user = null;
      this.name = null;
      this.level = null;
      this.email = null;
      this.cell = null;
      this.token = null;
    },
  },
});
