<template>
  <router-view/>
</template>

<style>
* {
    margin: 0;
    padding: 0;
}

#app{
  margin-left:74px;
}

html{

  background-color: #f9f9f9;
}

input[type="datetime-local"], input[type="date"]
{
    display:block;
    -webkit-appearance: none;
    -moz-appearance: none;
}
</style>
